import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ApiData,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { Currency } from "../../interfaces/api/currency.interface";
import { NgSelect } from "../../interfaces/ui/ui.interface";
import { ToastrNotificationService } from "../ui/toastr-notification.service";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  private apiUrl: string = environment.apiUrl;
  private path: string = "/currency";

  constructor(
    private _http: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  public findCurrencies(
    filter: object
  ): Observable<ApiResponse<ApiData<Currency[]>>> {
    const endpoint = `${this.apiUrl}${this.path}/find`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._http.get<ApiResponse<ApiData<Currency[]>>>(endpoint, {
      params,
    });
  }

  public findCurrenciesForSelect(
    filter: object
  ): Observable<ApiData<NgSelect<string>[]>> {
    return this.findCurrencies(filter).pipe(
      map((response) => ({
        totalCount: response.data.totalCount,
        result: response.data.result.map((currency) => ({
          label: currency.name,
          value: currency._id,
          group: "words.all",
        })),
      }))
    );
  }
}

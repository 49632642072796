import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, of, switchMap } from "rxjs";
import {
  USER_SESSION,
  USER_SESSION_PRE,
} from "../../helpers/global/global.constant";
import { AuthService } from "../../services/api/auth.service";
import { UserService } from "../../services/api/user.service";
import { StorageService } from "../../services/ui/storage.service";
import { UserActions } from "./auth.actions";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService
  ) {}

  loadUserSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserSession),
      exhaustMap(() => this.loadUserSession())
    )
  );

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userAuthenticationRequest),
      switchMap((action) => this.loginUser(action)),
      switchMap(() => this.reloadUserSessionAfterLogin())
    )
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userLogout),
      switchMap(() => {
        this.authService.logout();
        return [UserActions.completeUserLogout()];
      })
    )
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.requestUserProfileUpdate),
      switchMap((action) =>
        this.userService.updateProfile(action.user).pipe(
          map((res) =>
            UserActions.userProfileUpdateSuccess({ user: res.data })
          ),
          catchError((error) =>
            of(
              UserActions.userProfileUpdateFailure({
                message: error.message,
              })
            )
          )
        )
      )
    )
  );

  enableTwoFactorAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.enableTwoFactorAuthentication),
      switchMap((action) =>
        this.userService.activateTwoFactor(action._id, action.code).pipe(
          map((res) =>
            UserActions.enableTwoFactorAuthenticationSuccess({ user: res.data })
          ),
          catchError((error) =>
            of(
              UserActions.enableTwoFactorAuthenticationFailure({
                message: `Failed to enable Two-Factor Authentication: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  disableTwoFactorAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.disableTwoFactorAuthentication),
      switchMap((action) =>
        this.userService.disableTwoFactor(action._id).pipe(
          map((res) =>
            UserActions.disableTwoFactorAuthenticationSuccess({
              user: res.data,
            })
          ),
          catchError((error) =>
            of(
              UserActions.disableTwoFactorAuthenticationFailure({
                message: `Failed to disable Two-Factor Authentication: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  // Funciones privadas para separar lógica

  private loadUserSession() {
    const id = JSON.parse(
      this.storageService.secureStorage.getItem(USER_SESSION)
    );
    if (!id) {
      return of(
        UserActions.userAuthenticationFailure({
          message: "User session not found.",
        })
      );
    }
    return this.userService.getMeUser().pipe(
      map((user) => UserActions.userAuthenticationSuccess({ user: user.data })),
      catchError((error) =>
        of(
          UserActions.userAuthenticationFailure({
            message: `Failed to load session: ${error.message}`,
          })
        )
      )
    );
  }

  private loginUser(
    action: ReturnType<typeof UserActions.userAuthenticationRequest>
  ) {
    const { request } = action;
    return this.authService.login(request).pipe(
      map((user) => UserActions.userAuthenticationSuccess({ user: user.data })),
      catchError((error) =>
        of(
          UserActions.userAuthenticationFailure({
            message: `Login failed: ${error.message}`,
          })
        )
      )
    );
  }

  private reloadUserSessionAfterLogin() {
    const id =
      JSON.parse(this.storageService.secureStorage.getItem(USER_SESSION)) ||
      JSON.parse(this.storageService.secureStorage.getItem(USER_SESSION_PRE));

    if (!id) {
      return of(
        UserActions.userAuthenticationFailure({
          message: "User session not found.",
        })
      );
    }

    return this.userService.getMeUser().pipe(
      map((user) => {
        this.router.navigate([``]);
        return UserActions.userAuthenticationSuccess({ user: user.data });
      }),
      catchError((error) =>
        of(
          UserActions.userAuthenticationFailure({
            message: `Failed to load user data: ${error.message}`,
          })
        )
      )
    );
  }
}

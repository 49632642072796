import { createAction, props } from "@ngrx/store";
import { User, UserLoginRequest } from "../../interfaces/api/user.interface";

// Sesión y Autenticación
const loadUserSession = createAction("[User] Load Session");

const preAuthenticateUser = createAction("[User] Pre-Authenticate");

const userAuthenticationRequest = createAction(
  "[User] Authentication Request",
  props<{ request: UserLoginRequest }>()
);

const userAuthenticationSuccess = createAction(
  "[User] Authentication Success",
  props<{ user: User }>()
);

const preAuthenticationSuccess = createAction(
  "[User] Pre-Authentication Success",
  props<{ user: User }>()
);

const userAuthenticationFailure = createAction(
  "[User] Authentication Failure",
  props<{ message: string }>()
);

const preAuthenticationFailure = createAction(
  "[User] Pre-Authentication Failure",
  props<{ message: string }>()
);

// Logout
const userLogout = createAction("[User] Logout");

const completeUserLogout = createAction("[User] Complete Logout");

// Perfil de Usuario
const requestUserProfileUpdate = createAction(
  "[User] Request Profile Update",
  props<{ user: User }>()
);

const userProfileUpdateSuccess = createAction(
  "[User] Profile Update Success",
  props<{ user: User }>()
);

const userProfileUpdateFailure = createAction(
  "[User] Profile Update Failure",
  props<{ message: string }>()
);

// Two-Factor Authentication
const enableTwoFactorAuthentication = createAction(
  "[User] Enable Two-Factor Authentication",
  props<{ _id: string; code: string }>()
);

const enableTwoFactorAuthenticationSuccess = createAction(
  "[User] Enable Two-Factor Authentication Success",
  props<{ user: User }>()
);

const enableTwoFactorAuthenticationFailure = createAction(
  "[User] Enable Two-Factor Authentication Failure",
  props<{ message: string }>()
);

const disableTwoFactorAuthentication = createAction(
  "[User] Disable Two-Factor Authentication",
  props<{ _id: string }>()
);

const disableTwoFactorAuthenticationSuccess = createAction(
  "[User] Disable Two-Factor Authentication Success",
  props<{ user: User }>()
);

const disableTwoFactorAuthenticationFailure = createAction(
  "[User] Disable Two-Factor Authentication Failure",
  props<{ message: string }>()
);

export const UserActions = {
  // Sesión y Autenticación
  loadUserSession,
  preAuthenticateUser,
  userAuthenticationRequest,
  userAuthenticationSuccess,
  preAuthenticationSuccess,
  userAuthenticationFailure,
  preAuthenticationFailure,

  // Logout
  userLogout,
  completeUserLogout,

  // Perfil de Usuario
  requestUserProfileUpdate,
  userProfileUpdateSuccess,
  userProfileUpdateFailure,

  // Two-Factor Authentication
  enableTwoFactorAuthentication,
  enableTwoFactorAuthenticationSuccess,
  enableTwoFactorAuthenticationFailure,
  disableTwoFactorAuthentication,
  disableTwoFactorAuthenticationSuccess,
  disableTwoFactorAuthenticationFailure,
};

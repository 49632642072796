import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { environment } from "src/environments/environment";
import {
  ApiData,
  ApiMessage,
  ApiResponse,
} from "../../interfaces/api/api-response.interface";
import { ToastrNotificationService } from "../ui/toastr-notification.service";
import { TransferData } from "../../interfaces/api/financial.interface";
import { TransactionAdmin } from "../../interfaces/api/transaction.interface";

@Injectable({
  providedIn: "root",
})
export class TransferService {
  public API_URL = `${environment.apiUrl}/transaction`;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: ToastrNotificationService
  ) {}

  getAllTransactions(
    filter: object
  ): Observable<ApiResponse<ApiData<TransactionAdmin[]>>> {
    const endpoint = `${this.API_URL}/find-all-transactions-reports`;
    const params = new HttpParams({ fromObject: { ...filter } });
    return this._httpClient.get<ApiResponse<ApiData<TransactionAdmin[]>>>(
      endpoint,
      {
        params,
      }
    );
  }
  public saveTransfer(
    formData: FormData
  ): Observable<ApiResponse<TransferData>> {
    return this._httpClient
      .post<ApiResponse<TransferData>>(
        `${this.API_URL}/save-transaction`,
        formData
      )
      .pipe(tap((res) => this.showNotification(res.message)));
  }

  private showNotification(message: ApiMessage): void {
    this._notificationService.showNotification({
      title: "transfer.title",
      message: message,
      type: "success",
    });
  }
}

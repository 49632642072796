<div class="notification-box">
  <i class="icon-control-shuffle fs-5"></i>
</div>
<div class="onhover-show-div bookmark-flip">
  <div class="flip-card">
    <div class="flip-card-inner">
      <div class="light-card rounded-3">
        <h6 class="f-18 mb-0 dropdown-title">
          {{ "transfer.trasnferCash" | translate }}
        </h6>
        <ul class="light-card bookmark-dropdown">
          <li>
            <div class="row bookmark-scroll justify-content-center">
              <div class="col-auto text-center">
                <div class="bookmark-content">
                  <a (click)="openModal(USER_TYPES.AGENT)">
                    <div class="light-card bookmark-icon">
                      <i class="fa fa-user-secret fs-5 text-secondary"></i>
                    </div>
                    <span> {{ "transfer.agent" | translate }} </span>
                  </a>
                </div>
              </div>
              <div class="col-auto text-center">
                <div class="bookmark-content">
                  <a (click)="openModal(USER_TYPES.COLABORATOR)">
                    <div class="bookmark-icon">
                      <i class="fa fa-users fs-5 text-secondary"></i>
                    </div>
                    <span> {{ "transfer.colaborator" | translate }} </span>
                  </a>
                </div>
              </div>
              <div class="col-auto text-center">
                <div class="bookmark-content">
                  <a (click)="openModal(USER_TYPES.PLAYER)">
                    <div class="bookmark-icon">
                      <i class="fa fa-gamepad fs-5 text-secondary"></i>
                    </div>
                    <span> {{ "transfer.player" | translate }} </span>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

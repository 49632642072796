import { Component, OnInit } from "@angular/core";
import { BootstrapModalService } from "src/app/core/services/ui/bootstrap-modal.service";
import { TransferUserModalComponent } from "../transfer-user-modal/transfer-user-modal.component";
import { USER_TYPE } from "src/app/core/helpers/global/global.constant";

@Component({
  selector: "app-transfer-selector",
  templateUrl: "./transfer-selector.component.html",
})
export class TransferSelectorComponent implements OnInit {
  public USER_TYPES = USER_TYPE;

  constructor(private modalService: BootstrapModalService<string>) {}

  ngOnInit(): void {}

  openModal(selection: string): void {
    this.modalService.openModal({
      component: TransferUserModalComponent,
      data: selection,
    });
  }
}

// Modules
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { HttpLoaderFactory } from "../app.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "swiper/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxDropzoneModule } from "ngx-dropzone";

// Services
import { LayoutService } from "src/app/core/services/ui/layout.service";
import { NavService } from "src/app/core/services/ui/nav.service";

// Components
import { BlockPlayerComponent } from "./components/block-player/block-player.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { BootstrapModalComponent } from "./components/modals/bootstrap-modal/bootstrap-modal.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { CustomDateSelectorComponent } from "./components/datepicker/custom-date-selector/custom-date-selector.component";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FullComponent } from "./components/layout/full/full.component";
import { HeaderComponent } from "./components/header/header.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MovePlayerComponent } from "./components/move-player/move-player.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
import { NgxDatatableComponent } from "./components/tables/ngx-datatable/ngx-datatable.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { PlayerNoteFormComponent } from "./components/player-notes/player-notes.component";
import { SearchComponent } from "./components/header/elements/search/search.component";
import { SetLimitsComponent } from "./components/set-limits/set-limits.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { SwiperComponent } from "./components/header/elements/swiper/swiper.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
import { TooglePanelComponent } from "./components/accordions/toogle-panel/toogle-panel.component";
import { FormFieldErrorComponent } from "./components/form-field-error/form-field-error.component";
import { AddUsersComponent } from "./components/header/elements/add-users/add-users.component";
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { BalanceUserCardsComponent } from "./components/header/elements/balance-user-cards/balance-user-cards.component";
import { TransferSelectorComponent } from "./components/header/elements/transfer-selector/transfer-selector.component";
import { TransferUserModalComponent } from "./components/header/elements/transfer-user-modal/transfer-user-modal.component";
import { BalanceCardsFormComponent } from "./components/header/elements/forms/balance-cards-form/balance-cards-form.component";
import { TransferUserFormComponent } from "./components/header/elements/forms/transfer-user-form/transfer-user-form.component";

// Directives
import { DecimalInputDirective } from "./directives/decimal-input.directive";
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { MaxCharactersDirective } from "./directives/max-characters.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { CustomBadgeDirective } from "./directives/custom-badge.directive";
import { ShowWithPermissionDirective } from "./directives/show-with-permission.directive";
import { OnlyNumbersWhitTwoDecimalDirective } from "./directives/only-numbers-whit-two-decimal.directive";

// Pipes
import { BooleanToYesNoPipe } from "./pipes/boolean-to-yes-no.pipe";
import { CashdeskCommisionTypePipe } from "./pipes/cashdesk-commision-type.pipe";
import { CashdeskTypePipe } from "./pipes/cashdesk-type.pipe";
import { GenderToLabelPipe } from "./pipes/gender.pipe";
import { StatusToActiveInactivePipe } from "./pipes/active-descative.pipe";
import { BooleanToActiveInactivePipe } from "./pipes/boolean-to-active-inactive.pipe";
import { StatusMessagePipe } from "./pipes/status-message.pipe";

@NgModule({
  declarations: [
    // Componentes
    BlockPlayerComponent,
    BookmarkComponent,
    BootstrapModalComponent,
    BreadcrumbComponent,
    CartComponent,
    ChangePasswordComponent,
    ContentComponent,
    CustomDateSelectorComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FooterComponent,
    FullComponent,
    HeaderComponent,
    LanguagesComponent,
    LoaderComponent,
    MegaMenuComponent,
    MessageBoxComponent,
    MovePlayerComponent,
    MyAccountComponent,
    NgxDatatableComponent,
    NotificationComponent,
    PlayerNoteFormComponent,
    SearchComponent,
    SetLimitsComponent,
    SidebarComponent,
    SvgIconComponent,
    SwiperComponent,
    TapToTopComponent,
    TooglePanelComponent,
    BalanceUserCardsComponent,
    TransferSelectorComponent,
    TransferUserModalComponent,
    BalanceCardsFormComponent,
    TransferUserFormComponent,
    AddUsersComponent,
    ComingSoonComponent,
    FormFieldErrorComponent,

    // Directivas
    DecimalInputDirective,
    DisableKeyPressDirective,
    MaxCharactersDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    ShowWithPermissionDirective,
    CustomBadgeDirective,
    OnlyNumbersWhitTwoDecimalDirective,
    ShowWithPermissionDirective,

    // Pipes
    BooleanToYesNoPipe,
    CashdeskCommisionTypePipe,
    CashdeskTypePipe,
    GenderToLabelPipe,
    StatusToActiveInactivePipe,
    StatusMessagePipe,
    BooleanToActiveInactivePipe,
  ],
  imports: [
    CarouselModule,
    CommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    RouterModule,
    SwiperModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [NavService, LayoutService],
  exports: [
    // Modules
    FormsModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,

    // Components
    BlockPlayerComponent,
    BootstrapModalComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    CustomDateSelectorComponent,
    FeatherIconsComponent,
    LoaderComponent,
    MovePlayerComponent,
    NgxDatatableComponent,
    PlayerNoteFormComponent,
    SetLimitsComponent,
    SvgIconComponent,
    SwiperModule,
    TapToTopComponent,
    TooglePanelComponent,
    ComingSoonComponent,
    FormFieldErrorComponent,

    // Directives
    DecimalInputDirective,
    DisableKeyPressDirective,
    MaxCharactersDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    ShowWithPermissionDirective,
    CustomBadgeDirective,
    OnlyNumbersWhitTwoDecimalDirective,

    // Pipes
    BooleanToYesNoPipe,
    CashdeskCommisionTypePipe,
    CashdeskTypePipe,
    GenderToLabelPipe,
    StatusToActiveInactivePipe,
    StatusMessagePipe,
    BooleanToActiveInactivePipe,
  ],
})
export class SharedModule {}

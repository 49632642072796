import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export function validateIpAddress(): ValidatorFn {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return (control: AbstractControl): { invalidIp: { value: any } } | null => {
    const valid = ipRegex.test(control.value);
    return valid ? null : { invalidIp: { value: control.value } };
  };
}

export function duplicateCurrency(): ValidatorFn {
  return (formArray: AbstractControl): { [key: string]: boolean } | null => {
    if (!(formArray instanceof FormArray)) {
      return null;
    }

    const currencies = formArray.value.map(
      (currency: any) => currency.currencyId
    );
    const hasDuplicates = currencies.some(
      (item: any, index: number) => currencies.indexOf(item) !== index
    );

    return hasDuplicates ? { duplicateCurrency: true } : null;
  };
}

<div class="d-flex">
  <div
    class="media profile-media light-card rounded rounded-pill me-2 position-relative"
    (mouseenter)="showList(BALANCE_TYPE.BALANCE)"
    (mouseleave)="hideList(BALANCE_TYPE.BALANCE)"
    (click)="toggleList(BALANCE_TYPE.BALANCE)"
  >
    <img class="b-r-10 img-30" src="assets/images/icons/coin.png" alt="" />
    <div class="media-body pe-3">
      <span> {{ "profile.balance.balance" | translate }} </span>
      <p class="mb-1">{{ balanceUser | number : "1.2-2" }}</p>
    </div>
    <ul
      class="light-card wallet-list position-absolute"
      [ngClass]="{ 'd-block': isBalanceListVisible }"
    >
      @if (otherCurrencies.length === 0) {

      <li>
        {{ "profile.balance.notMoreWallets" | translate }}
      </li>

      } @else { @for (currency of otherCurrencies; track $index) {

      <li>{{ currency.name }} {{ currency.balance | number : "1.2-2" }}</li>

      } }
    </ul>
  </div>
  <div
    class="media profile-media light-card rounded rounded-pill position-relative"
    (mouseenter)="showList(BALANCE_TYPE.CREDIT)"
    (mouseleave)="hideList(BALANCE_TYPE.CREDIT)"
    (click)="toggleList(BALANCE_TYPE.CREDIT)"
  >
    <img class="b-r-10 img-30" src="assets/images/icons/wallet.png" alt="" />
    <div class="media-body pe-3">
      <span>{{ "profile.balance.credit" | translate }}</span>
      <p class="mb-1">{{ creditUser | number : "1.2-2" }}</p>
    </div>
    <ul
      class="light-card wallet-list position-absolute"
      [ngClass]="{ 'd-block': isCreditListVisible }"
    >
      @if (otherCurrencies.length === 0) {

      <li>
        {{ "profile.balance.notMoreWallets" | translate }}
      </li>

      } @else { @for (currency of otherCurrencies; track $index) {

      <li>{{ currency.name }} {{ currency.credit | number : "1.2-2" }}</li>

      } }
    </ul>
  </div>
</div>
